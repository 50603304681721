<template>
  <div>
    <div class="site-section bg-light">

      <div class="container">
        <div class="row justify-content-center">
          <div v-if="info.show_about==1" class="col-md-12" data-aos="fade-up" data-aos-delay="100">
            <h1 class="mb-2" style="text-align: center;">{{$t("pages.about.company.title")}}</h1>
            <p v-if="activeLocale == 'es'" class="text-justify text-uppercase font-uppercase jump-line">
              <!-- Si campo en español no está vacío, mostrarlo, si está vacío no mostrarlo  -->
              {{ info.about_us !== '' ? info.about_us : '' }}
            </p>

            <p v-else="activeLocale == 'en'" class="text-justify text-uppercase font-uppercase jump-line">
              {{ info.about_us_eng != '' || info.about_us_eng != 0 ? info.about_us_eng : '' }}
            </p>
          
          </div>
        </div>
        <div class="row" v-if="info.slogan_check == 1" >
          <div class="col-md-1"></div>
          <div class="post-content  col-md-10" >
            <p v-if="activeLocale == 'es'" class="text-center text-uppercase font-uppercase quote">
              <!-- Si campo en español no está vacío, mostrarlo, si está vacío no mostrarlo  -->
              {{ info.our_slogan !== '' ? info.our_slogan : '' }}
            </p>
            <p v-else="activeLocale == 'en'" class="text-center text-uppercase font-uppercase quote">
              {{ info.our_slogan_eng != '' || info.our_slogan_eng != 0 ? info.our_slogan_eng : '' }}
            </p>
          </div>
        </div>
          <br />

        <div class="row">
          <div v-if="info.mission_check== 1" class="col-sm-12 col-md">
            <h2 class="service-heading text-center">{{$t("pages.about.mision.title")}}</h2>
            <p v-if="activeLocale == 'es'" class="text-justify text-uppercase font-uppercase jump-line">
              <!-- Si campo en español no está vacío, mostrarlo, si está vacío no mostrarlo  -->
              {{ info.our_mission !== '' ? info.our_mission : '' }}
            </p>
            <p v-else="activeLocale == 'en'" class="text-justify text-uppercase font-uppercase jump-line">
              {{ info.our_mission_eng != '' || info.our_mission_eng != 0 ? info.our_mission_eng : '' }}
            </p>
          </div>

          <div v-if="info.vision_check== 1" class="col-sm-12 col-md">
            <h2 class="service-heading text-center">{{$t("pages.about.vision.title")}}</h2>
            <p v-if="activeLocale == 'es'" class="text-justify text-uppercase font-uppercase jump-line">
              <!-- Si campo en español no está vacío, mostrarlo, si está vacío no mostrarlo  -->
              {{ info.our_vision !== '' ? info.our_vision : '' }}
            </p>
            <p v-else="activeLocale == 'en'" class="text-justify text-uppercase font-uppercase jump-line">
              {{ info.our_vision_eng != '' || info.our_vision_eng != 0 ? info.our_vision_eng : '' }}
            </p>
          </div>
          <div v-if="info.show_values== 1" class="col-sm-12 col-md">
            <h2 class="service-heading text-center">{{$t("pages.about.values.title")}}</h2>
            <p v-if="activeLocale == 'es'" class="text-justify text-uppercase font-uppercase jump-line">
              <!-- Si campo en español no está vacío, mostrarlo, si está vacío no mostrarlo  -->
              {{ info.our_values !== '' ? info.our_values : '' }}
            </p>
            <p v-else="activeLocale == 'en'" class="text-justify text-uppercase font-uppercase jump-line">
              {{ info.our_values_eng != '' || info.our_values_eng != 0 ? info.our_values_eng : '' }}
            </p>

          </div>
        </div>
        
      </div>
    </div>

  </div>
</template>


<script>
import { mapGetters } from "vuex";
export default {

  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
    activeLocale() {
      return this.$i18n.locale;
    },
  },
  created() {
     window.scrollTo(0, 0);
    console.log("create footer");
  },
};
</script>
<style scoped>
.jump-line{
  word-wrap: break-word;
  white-space: pre-wrap;
}
</style>